import React from 'react'
import shape13 from '../../assets/images/shape/shape-13.png'
import shape14 from '../../assets/images/shape/shape-14.png'
import shape15 from '../../assets/images/shape/shape-15.png'

function FI({ className }) {
  return (
    <>
      <section
        className={`appie-download-area pt-150 pb-160 mb-90 ${className || ''}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="appie-download-content">
                <span>Download Our App</span>
                <h3 className="title">
                  Registrerad hos <br />
                  Finansinspektionen
                </h3>
                <p>
                  När du handlar med Virtune kan du känna dig trygg i att du gör
                  affärer med ett svenskt företag registrerat hos
                  Finansinspektionen. Det innebär att vi uppfyller
                  Finansinspektionens krav och vidtar åtgärder som skyddar dig
                  som kund.
                </p>
                <ul>
                  <li>
                    <a href="#">
                      <span></span>
                    </a>
                  </li>
                  <li>
                    <a className="item-2" href="#">
                      <span></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="download-shape-1">
          <img src={shape15} alt="" />
        </div>
        <div className="download-shape-2">
          <img src={shape14} alt="" />
        </div>
        <div className="download-shape-3">
          <img src={shape13} alt="" />
        </div>
      </section>
    </>
  )
}

export default FI
